export default function () {
    const currentRoute = useRoute();
    const localePath = useLocalePath();
    const isAuthenticated = useStateIsAuthenticated();
    if (!isAuthenticated.value) {
        return navigateTo(
            localePath("/sign-in") + "?" + new URLSearchParams({ returnUrl: currentRoute.fullPath }).toString(),
            { external: true } // this is needed until the sign-in route is migrated to Nuxt
        );
    }
}
