<template>
    <section class="flex flex-col text-base">
        <PageHeader wide>
            <h1>{{ $t("MyAccount.Title") }}</h1>
        </PageHeader>
        <div class="container-left flex flex-col gap-4 mt-8">
            <Tabs v-model="tab" class="mb-16">
                <TabsList class="w-full">
                    <TabsTrigger v-for="page in pages" :key="page.id" :value="page.id">
                        {{ page.title }}
                    </TabsTrigger>
                </TabsList>
            </Tabs>
            <NuxtPage v-if="isAuthenticated" />
        </div>
    </section>
</template>
<script setup lang="ts">
import { MY_ACCOUNT_PAGES } from "~/utils/constants";

await useRequireAuthentication();

const isAuthenticated = useStateIsAuthenticated();
const { t } = useI18n();
const localePath = useLangPath();
const route = useRoute();
const tab = ref("");
const { data: apiRecord } = await useFetchLocaleApi<APICustomer>("api/account/api-customer");

type PageType = {
    id: MY_ACCOUNT_PAGES;
    title: string;
};

const pages: PageType[] = [
    { id: MY_ACCOUNT_PAGES.PROFILE, title: t("MyAccount.ProfileTab") },
    { id: MY_ACCOUNT_PAGES.BOMS, title: t("MyAccount.BOMsTab") },
    { id: MY_ACCOUNT_PAGES.NOTIFICATIONS, title: t("MyAccount.NotificationsTab") },
    { id: MY_ACCOUNT_PAGES.NOTIFICATION_EMAILS, title: t("MyAccount.NotificationAddressesTab") },
    { id: MY_ACCOUNT_PAGES.CHANGE_PASSWORD, title: t("Change_Password.PageTitle") },
    ...(apiRecord.value ? [{ id: MY_ACCOUNT_PAGES.API_KEY, title: t("MyAccount.APIKey") }] : []),
    { id: MY_ACCOUNT_PAGES.ADDITIONAL_FEATURES, title: t("AdditionalFeatures.Header") },
];

watch(tab, () => {
    navigateTo(localePath("/my-account/" + tab.value));
});

onMounted(() => {
    const lastSegment = route.path.split("/").pop() ?? "profile";
    tab.value = lastSegment == "my-account" ? "profile" : lastSegment;
});
</script>
